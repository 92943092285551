<template>
    <Slider v-model="value" :min="min" :max="max" :step="step" :format="format" />
    <input v-model="value" type="hidden" :name="handle">
</template>

<script>
import Slider from '@vueform/slider';

export default {
    name: 'RangeSliderField',

    components: {
        Slider,
    },

    props: {
        min: {
            type: Number,
            required: true,
        },

        max: {
            type: Number,
            required: true,
        },

        handle: {
            type: String,
            required: true,
        },

        step: {
            type: Number,
            required: true,
        },

        defaultValue: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            value: this.defaultValue,
        };
    },

    methods: {
        format(value) {
            return Intl.NumberFormat('en-AU', {
                style: 'currency', currency: 'AUD',
            }).format(value);
        },
    },
};
</script>
