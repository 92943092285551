// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';
import '@splidejs/splide/css/core';
import '@splidejs/splide/css/skyblue';

// CSS needs to be imported here as it's treated as a module
import '@/scss/style.scss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';

// Flickity - please use `data-flickity` options to configure
import 'flickity';
import 'flickity-bg-lazyload';

// An excellent, responsive and lightweight lightbox
import 'spotlight.js';

import {
    Splide,
    SplideTrack,
    SplideSlide,
} from '@splidejs/vue-splide';


// ================================================
// SUI Components
// ================================================

import MobileNav from '@sgroup/mobile-nav';
import ClickToggle from '@sgroup/click-toggle';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp } from 'vue';

// Import all globally-available components here. Be responsible please.
import {
    Dialog,
    DialogPanel,
    DialogOverlay,
    DialogTitle,
    DialogDescription,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    TransitionRoot,
    TransitionChild,
} from '@headlessui/vue';

import RangeSliderField from '@components/RangeSliderField.vue';
import LazyHeader from '@components/LazyHeader.vue';

createApp({
    delimiters: ['${', '}'],

    components: {
        HuiDisclosure: Disclosure,
        HuiDisclosureButton: DisclosureButton,
        HuiDisclosurePanel: DisclosurePanel,
        HuiDialog: Dialog,
        HuiDialogPanel: DialogPanel,
        HuiDialogOverlay: DialogOverlay,
        HuiDialogTitle: DialogTitle,
        HuiDialogDescription: DialogDescription,
        HuiTabGroup: TabGroup,
        HuiTabList: TabList,
        HuiTab: Tab,
        HuiTabPanels: TabPanels,
        HuiTabPanel: TabPanel,
        RangeSliderField,
        LazyHeader,
        SplideCarousel: Splide,
        SplideCarouselTrack: SplideTrack,
        SplideCarouselSlide: SplideSlide,
        TransitionRoot,
        TransitionChild,
    },

    data() {
        return {
            showSticky: false,
            showHeader: true,
            stickyHeader: false,
            width: window.innerWidth,
            openModal: false,
        };
    },

    mounted() {
        // Because we're using Vue, anything that interacts with the DOM should go here.

        // A minimal responsive menu with just vanilla JS
        new MobileNav();

        // A search box for header. Handles clicking away
        new ClickToggle({
            openSelector: '[data-search-open]',
            activeClass: 'search-open',
            clickAwaySelector: '[data-header-search-form]',
        });

        // quick links drop down redirect

        // const quickLink = document.getElementById('quick-link-dd');
        const quickLink = document.body.querySelector('.js-quick-link-dd');

        if (quickLink) {
            quickLink.addEventListener('change', function() {
                if (this.value) {
                    window.location.href = this.value;
                }
            });
        }
        // Delay open of modal
        setTimeout(() => {
            this.openModal = !this.openModal;
        }, '10000');
    },
    methods: {

        toggleModal() {
            this.openModal = !this.openModal;

            document.cookie = 'modal=modal; path=/; max-age=86400;';
        },
    },
}).mount('#app');
